<script>
import { onMounted, ref, nextTick, watch } from 'vue'
import QRCode from 'qrcodejs2'
import { uuid, dataURLtoFile } from '@/utils'
import qiniuUpload from '@/utils/qiniu-upload'
import html2canvas from 'html2canvas'
import { Toast, Overlay } from 'vant'
import { http } from '@/http'
import wx from '@/utils/weixin'
import { needLoading } from '@/reactive'

export default {
  setup() {
    let qrcodeUrl = ref('')
    let overlayVisible = ref(false)
    let qrcodeRef = ref(null)
    let qrcode = null

    function getBrokerQrcodeUrl() {
      needLoading.value = true
      return http.post('/?cmd=com.xqxc.api.pigear.biz.account.BrokerService.getBrokerQrcodeUrl').then(res => {
        if(res.result) {
          qrcodeUrl.value = res.result
        }
      })
    }

    function handleBillBuild() {
      let page = document.querySelector('.page')
      let toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '正在生成海报',
      })
      html2canvas(page, {
        useCORS: true,
        backgroundColor: 'transparent',
        ignoreElements:(el) => {
          if(el === document.getElementById('btn')) {
            return true
          }
          return false
        }
      }).then(canvas => {
        let files = dataURLtoFile(canvas.toDataURL(), uuid())
        qiniuUpload(files, {
          errorCallback() {
          },
          completeCallback(res) {
            toast.clear()
            overlayVisible.value = true
            nextTick(() => {
              document.getElementById('billImage').src = res.result 
            })
            
          }
        })
      })
    }

    onMounted(() => {
      qrcode = document.getElementById('qrcode')
      getBrokerQrcodeUrl().then(() => {
        try {
          qrcode.innerHTML = ''
          qrcodeRef.value = new QRCode(qrcode, {
            text: qrcodeUrl.value,
            width: 180,
            height:180
          })        
          let canvas = document.querySelectorAll('canvas')
          if(canvas[0]) {
            let files = dataURLtoFile(canvas[0].toDataURL(), uuid())
            qiniuUpload(files, {
              errorCallback() {
              },
              completeCallback(res) {
                let img = document.createElement('img')
                img.setAttribute('src', res.result) 
                qrcode.appendChild(img)
                // 搞不懂微信的规则。。。
                qrcode.style.visibility = 'visible'
                if(document.querySelectorAll('img')[1]){
                  document.querySelectorAll('img')[1].crossOrigin = 'anonymous'
                  document.querySelectorAll('img')[1].style.cssText = 'z-index:1000; opacity:1'
                }
                
              }
            })
          }
        } catch(e) {
          // console.log(e)
        }
      
      })
    })

    watch(() => qrcodeUrl.value, (link, prevLink) => {
      wx.setup({
        shareUrl: window.location.origin+'/#/channel-invite',
        imgUrl: window.location.origin + '/icon500.png',
        title: '猪耳朵渠道注册',
        desc: '猪耳朵省时、省力、更省钱，聚合平台领跑者',
        link
      })
    })

    return {
      handleBillBuild,
      overlayVisible,
    }
  }
  
}
</script>
<template>
  <div class="page">
    <div class="box">
      <span>扫码领取</span>
      <div class="qrcode" id="qrcode" style="visibility: hidden"></div>
    </div>
    <div class="btn-area" id="btn">
      <button @click="handleBillBuild">保存海报</button>
    </div>
    <van-overlay z-index="2001" :show="overlayVisible" @click="overlayVisible = false">
      <div class="wrapper">
        <div style="text-align:center">
          <img id="billImage" style="width:65%" />
          <p style="padding-top: .2rem;color:#fff; font-size:.2rem">长按上方图片保存到手机</p>
        </div>
      </div>
    </van-overlay>
  </div>

</template>
<style scoped>
    .page { background-position: center top; padding-top: 8.3rem;background-size: 100%; background-image: url("~@/assets/images/bkgd2.png"); background-color: #f52a1e}
    .box {
      text-align:center;
      width: 6.9rem;
      height: 7rem;
      background-size: cover; 
      margin: auto;
      background-image: url("~@/assets/images/box-bg1.png");
    }
    .box span {
      display: block;
      color: #E12722;
      font-size: .36rem;
      padding-top: .25rem;
    }
    .btn-area {
      margin: 1.07rem 0 .5rem;
      padding: 0 .22rem;
    }
    .btn-area button {
      border: none;
      width: 100%;
      height: 1.07rem;
      background: transparent;
      background-size: cover;
      background-image: url("~@/assets/images/btn-bg2.png");
      font-size: .36rem;
      color: #E22723;
      font-weight: bold;
    }
    .qrcode {
  position: relative;
  margin: 1.08rem auto;
  width: 4.4rem;
  height: 4.4rem;
  user-select: none;
}
/deep/ .qrcode img{
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  width: 100%;
  height: 100%;
  opacity:  0;
}
</style>